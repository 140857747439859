<script>
import { Modal } from 'epmc-patterns/components/v2'
import Feedback from '@/templates/Feedback'

export default {
  components: { Feedback, Modal },
  data() {
    return {
      pageUrl: location.href,
    }
  },
  methods: {
    close(e) {
      this.$emit('close', e)
    },
  },
}
</script>
<template>
  <modal
    id="feedback-modal"
    class="large"
    :close-when-clicking-outside="true"
    @close="close"
  >
    <template slot="title">
      <i class="fas fa-comments"></i>
      Feedback
    </template>
    <feedback :page-url="pageUrl" @close="close" />
  </modal>
</template>
